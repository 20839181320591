var _ = require('underscore');
var Backbone = require('backbone');
var CartoError = require('../error');

/**
 * Base source object.
 *
 * @constructor
 * @abstract
 * @memberof carto.source
 * @api
 */
function Base () {
  this._id = Base.$generateId();
}

_.extend(Base.prototype, Backbone.Events);

/**
 * The instance id will be autogenerated by incrementing this variable.
 */
Base.$nextId = 0;

/**
 * Static funciton used internally to autogenerate source ids.
 */
Base.$generateId = function () {
  return 'S' + ++Base.$nextId;
};

/**
 * Return a unique autogenerated id.
 * 
 * @return {string} Unique autogenerated id
 */
Base.prototype.getId = function () {
  return this._id;
};

Base.prototype._createInternalModel = function (engine) {
  throw new Error('_createInternalModel must be implemented by the particular source');
};

/**
 * Fire a CartoError event from a internalError
 */
Base.prototype._triggerError = function (model, internalError) {
  this.trigger('error', new CartoError(internalError));
};

Base.prototype.$setEngine = function (engine) {
  if (!this._internalModel) {
    this._internalModel = this._createInternalModel(engine);
    this._internalModel.on('change:error', this._triggerError, this);
  }
};

/**
 * Return the engine form the source internal model
 */
Base.prototype.$getEngine = function (engine) {
  if (this._internalModel) {
    return this._internalModel._engine;
  }
};

/**
 * Return the real carto.js model used by the source.
 */
Base.prototype.$getInternalModel = function () {
  return this._internalModel;
};

module.exports = Base;
