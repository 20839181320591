var BoundingBox = require('./bounding-box');
var BoundingBoxLeaflet = require('./bounding-box-leaflet');

/**
 *  @namespace carto.filter
 *  @api
 */
module.exports = {
  BoundingBox: BoundingBox,
  BoundingBoxLeaflet: BoundingBoxLeaflet
};
