module.exports={
    "version": "0.55.1",
    "analyses": {
        "aggregate-intersection": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "target": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "aggregate_function": {
                    "type": "enum",
                    "values": [
                        "avg",
                        "count",
                        "max",
                        "min",
                        "sum"
                    ]
                },
                "aggregate_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "bounding-box": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                },
                "aggregation": {
                    "type": "enum",
                    "values": [
                        "avg",
                        "count",
                        "max",
                        "min",
                        "sum"
                    ],
                    "optional": true,
                    "default-value": "count"
                },
                "aggregation_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "bounding-circle": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                },
                "aggregation": {
                    "type": "enum",
                    "values": [
                        "avg",
                        "count",
                        "max",
                        "min",
                        "sum"
                    ],
                    "optional": true,
                    "default-value": "count"
                },
                "aggregation_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "buffer": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "radius": {
                    "type": "number"
                },
                "isolines": {
                    "type": "number",
                    "optional": true
                },
                "dissolved": {
                    "type": "boolean",
                    "optional": true
                }
            }
        },
        "centroid": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                },
                "aggregation": {
                    "type": "string",
                    "optional": true,
                    "default-value": "count"
                },
                "aggregation_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "closest": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "target": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "responses": {
                    "type": "number",
                    "optional": true,
                    "default-value": 1
                },
                "category": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "concave-hull": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                },
                "target_percent": {
                    "type": "number",
                    "optional": true,
                    "default-value": 0.7
                },
                "allow_holes": {
                    "type": "boolean",
                    "optional": true,
                    "default-value": false
                },
                "aggregation": {
                    "type": "enum",
                    "values": [
                        "avg",
                        "count",
                        "max",
                        "min",
                        "sum"
                    ],
                    "optional": true,
                    "default-value": "count"
                },
                "aggregation_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "contour": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "column": {
                    "type": "string"
                },
                "buffer": {
                    "type": "number",
                    "optional": true,
                    "default-value": 0.2
                },
                "method": {
                    "type": "enum",
                    "values": [
                        "nearest_neighbor",
                        "barymetric",
                        "IDW"
                    ],
                    "optional": true,
                    "default-value": "barymetric"
                },
                "class_method": {
                    "type": "enum",
                    "values": [
                        "equals",
                        "headstails",
                        "jenks",
                        "quantiles"
                    ],
                    "optional": true,
                    "default-value": "quantiles"
                },
                "steps": {
                    "type": "number",
                    "optional": true,
                    "default-value": 7
                },
                "resolution": {
                    "type": "number",
                    "optional": true,
                    "default-value": -90
                }
            }
        },
        "convex-hull": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                },
                "aggregation": {
                    "type": "enum",
                    "values": [
                        "avg",
                        "count",
                        "max",
                        "min",
                        "sum"
                    ],
                    "optional": true,
                    "default-value": "count"
                },
                "aggregation_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "data-observatory-measure": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point",
                        "polygon"
                    ]
                },
                "final_column": {
                    "type": "string"
                },
                "segment_name": {
                    "type": "string"
                },
                "percent": {
                    "type": "boolean",
                    "optional": true
                }
            }
        },
        "data-observatory-multiple-measures": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point",
                        "polygon"
                    ]
                },
                "numerators": {
                    "type": "array"
                },
                "normalizations": {
                    "type": "array"
                },
                "denominators": {
                    "type": "array"
                },
                "geom_ids": {
                    "type": "array"
                },
                "numerator_timespans": {
                    "type": "array"
                },
                "column_names": {
                    "type": "array"
                }
            }
        },
        "deprecated-sql-function": {
            "params": {
                "function_name": {
                    "type": "string"
                },
                "primary_source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "secondary_source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ],
                    "optional": true
                },
                "function_args": {
                    "type": "array",
                    "optional": true
                }
            }
        },
        "filter-by-node-column": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "column": {
                    "type": "string"
                },
                "filter_source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "filter_column": {
                    "type": "string"
                }
            }
        },
        "filter-category": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "column": {
                    "type": "string"
                },
                "accept": {
                    "type": "array",
                    "optional": true
                },
                "reject": {
                    "type": "array",
                    "optional": true
                }
            }
        },
        "filter-grouped-rank": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "column": {
                    "type": "string"
                },
                "rank": {
                    "type": "enum",
                    "values": [
                        "top",
                        "bottom"
                    ]
                },
                "group": {
                    "type": "string"
                },
                "min": {
                    "type": "number",
                    "optional": true
                },
                "max": {
                    "type": "number",
                    "optional": true
                }
            }
        },
        "filter-range": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "column": {
                    "type": "string"
                },
                "min": {
                    "type": "number",
                    "optional": true
                },
                "max": {
                    "type": "number",
                    "optional": true
                }
            }
        },
        "filter-rank": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "column": {
                    "type": "string"
                },
                "rank": {
                    "type": "enum",
                    "values": [
                        "top",
                        "bottom"
                    ]
                },
                "limit": {
                    "type": "number"
                },
                "action": {
                    "type": "enum",
                    "values": [
                        "show",
                        "hide"
                    ],
                    "optional": true,
                    "default-value": "show"
                }
            }
        },
        "georeference-admin-region": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "admin_region_column": {
                    "type": "string"
                },
                "country": {
                    "type": "string",
                    "optional": true
                },
                "country_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "georeference-city": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "city_column": {
                    "type": "string"
                },
                "admin_region": {
                    "type": "string",
                    "optional": true
                },
                "admin_region_column": {
                    "type": "string",
                    "optional": true
                },
                "country": {
                    "type": "string",
                    "optional": true
                },
                "country_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "georeference-country": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "country_column": {
                    "type": "string"
                }
            }
        },
        "georeference-ip-address": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "ip_address": {
                    "type": "string"
                }
            }
        },
        "georeference-long-lat": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "longitude": {
                    "type": "string"
                },
                "latitude": {
                    "type": "string"
                }
            }
        },
        "georeference-postal-code": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "output_geometry_type": {
                    "type": "enum",
                    "values": [
                        "point",
                        "polygon"
                    ],
                    "optional": true,
                    "default-value": "polygon"
                },
                "postal_code_column": {
                    "type": "string"
                },
                "country": {
                    "type": "string",
                    "optional": true
                },
                "country_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "georeference-street-address": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "street_address_column": {
                    "type": "string",
                    "optional": true
                },
                "street_address_template": {
                    "type": "string",
                    "optional": true
                },
                "city": {
                    "type": "string",
                    "optional": true
                },
                "city_column": {
                    "type": "string",
                    "optional": true
                },
                "state": {
                    "type": "string",
                    "optional": true
                },
                "state_column": {
                    "type": "string",
                    "optional": true
                },
                "country": {
                    "type": "string",
                    "optional": true
                },
                "country_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "gravity": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "target": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "weight_column": {
                    "type": "string"
                },
                "weight_threshold": {
                    "type": "number",
                    "optional": true,
                    "default-value": -1e+308
                },
                "pop_column": {
                    "type": "string"
                },
                "max_distance": {
                    "type": "number"
                },
                "target_id": {
                    "type": "number"
                }
            }
        },
        "intersection": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "source_columns": {
                    "type": "array",
                    "optional": true,
                    "default-value": []
                },
                "target": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                }
            }
        },
        "kmeans": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "clusters": {
                    "type": "number"
                }
            }
        },
        "line-sequential": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "order_column": {
                    "type": "string"
                },
                "order_type": {
                    "type": "enum",
                    "values": [
                        "asc",
                        "desc"
                    ]
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                }
            }
        },
        "line-source-to-target": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "source_column": {
                    "type": "string",
                    "optional": true
                },
                "target": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "target_column": {
                    "type": "string",
                    "optional": true
                },
                "closest": {
                    "type": "boolean"
                }
            }
        },
        "line-to-column": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "target_column": {
                    "type": "string"
                }
            }
        },
        "line-to-single-point": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "destination_longitude": {
                    "type": "number"
                },
                "destination_latitude": {
                    "type": "number"
                }
            }
        },
        "link-by-line": {
            "params": {
                "source_points": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "destination_points": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "source_column": {
                    "type": "string"
                },
                "destination_column": {
                    "type": "string"
                },
                "use_great_circle": {
                    "type": "boolean",
                    "optional": true,
                    "default-value": false
                }
            }
        },
        "merge": {
            "params": {
                "left_source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "right_source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "left_source_column": {
                    "type": "string"
                },
                "right_source_column": {
                    "type": "string"
                },
                "join_operator": {
                    "type": "enum",
                    "values": [
                        "inner",
                        "left",
                        "right"
                    ],
                    "optional": true,
                    "default-value": "inner"
                },
                "source_geometry": {
                    "type": "enum",
                    "values": [
                        "left_source",
                        "right_source"
                    ],
                    "optional": true,
                    "default-value": "left_source"
                },
                "left_source_columns": {
                    "type": "array",
                    "optional": true
                },
                "right_source_columns": {
                    "type": "array",
                    "optional": true,
                    "default-value": []
                }
            }
        },
        "moran": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "polygon",
                        "point"
                    ]
                },
                "numerator_column": {
                    "type": "string"
                },
                "denominator_column": {
                    "type": "string",
                    "optional": true
                },
                "significance": {
                    "type": "number"
                },
                "neighbours": {
                    "type": "number",
                    "optional": true
                },
                "permutations": {
                    "type": "number",
                    "optional": true
                },
                "w_type": {
                    "type": "enum",
                    "values": [
                        "knn",
                        "queen"
                    ],
                    "optional": true,
                    "default-value": "knn"
                }
            }
        },
        "point-in-polygon": {
            "params": {
                "points_source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "polygons_source": {
                    "type": "node",
                    "geometry": [
                        "polygon"
                    ]
                }
            }
        },
        "population-in-area": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "polygon"
                    ]
                },
                "final_column": {
                    "type": "string"
                }
            }
        },
        "routing-sequential": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "mode": {
                    "type": "enum",
                    "values": [
                        "car",
                        "walk",
                        "bicycle",
                        "public_transport"
                    ]
                },
                "units": {
                    "type": "enum",
                    "values": [
                        "kilometers",
                        "miles"
                    ],
                    "optional": true,
                    "default-value": "kilometers"
                },
                "order_column": {
                    "type": "string",
                    "optional": true,
                    "default-value": "cartodb_id"
                },
                "order_type": {
                    "type": "enum",
                    "values": [
                        "asc",
                        "desc"
                    ],
                    "optional": true,
                    "default-value": "asc"
                }
            }
        },
        "routing-to-layer-all-to-all": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "source_column": {
                    "type": "string"
                },
                "target": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "target_column": {
                    "type": "string"
                },
                "mode": {
                    "type": "enum",
                    "values": [
                        "car",
                        "walk",
                        "bicycle",
                        "public_transport"
                    ]
                },
                "units": {
                    "type": "enum",
                    "values": [
                        "kilometers",
                        "miles"
                    ],
                    "optional": true,
                    "default-value": "kilometers"
                },
                "closest": {
                    "type": "boolean"
                }
            }
        },
        "routing-to-single-point": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "mode": {
                    "type": "enum",
                    "values": [
                        "car",
                        "walk",
                        "bicycle",
                        "public_transport"
                    ]
                },
                "destination_longitude": {
                    "type": "number"
                },
                "destination_latitude": {
                    "type": "number"
                },
                "units": {
                    "type": "enum",
                    "values": [
                        "kilometers",
                        "miles"
                    ],
                    "optional": true,
                    "default-value": "kilometers"
                }
            }
        },
        "sampling": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "sampling": {
                    "type": "number"
                },
                "seed": {
                    "type": "number",
                    "optional": true
                }
            }
        },
        "source": {
            "params": {
                "query": {
                    "type": "string"
                }
            }
        },
        "spatial-markov-trend": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "time_columns": {
                    "type": "array"
                },
                "num_classes": {
                    "type": "number",
                    "optional": true,
                    "default-value": 5
                },
                "weight_type": {
                    "type": "string",
                    "optional": true,
                    "default-value": "knn"
                },
                "num_ngbrs": {
                    "type": "number",
                    "optional": true,
                    "default-value": 5
                },
                "permutations": {
                    "type": "number",
                    "optional": true,
                    "default-value": 0
                },
                "geom_col": {
                    "type": "string",
                    "optional": true,
                    "default-value": "the_geom"
                },
                "id_col": {
                    "type": "string",
                    "optional": true,
                    "default-value": "cartodb_id"
                }
            }
        },
        "trade-area": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "point"
                    ]
                },
                "kind": {
                    "type": "enum",
                    "values": [
                        "walk",
                        "car"
                    ]
                },
                "time": {
                    "type": "number"
                },
                "isolines": {
                    "type": "number"
                },
                "dissolved": {
                    "type": "boolean"
                }
            }
        },
        "weighted-centroid": {
            "params": {
                "source": {
                    "type": "node",
                    "geometry": [
                        "*"
                    ]
                },
                "weight_column": {
                    "type": "string"
                },
                "category_column": {
                    "type": "string",
                    "optional": true
                },
                "aggregation": {
                    "type": "string",
                    "optional": true,
                    "default-value": "count"
                },
                "aggregation_column": {
                    "type": "string",
                    "optional": true
                }
            }
        }
    }
}