var _ = require('underscore');
var Backbone = require('backbone');

/**
 * Base layer object.
 *
 * @constructor
 * @abstract
 * @memberof carto.layer
 * @api
 */
function Base () {
  this._id = Base.$generateId();
}

_.extend(Base.prototype, Backbone.Events);

/**
 * Return a unique autogenerated id.
 * 
 * @return {string} Unique autogenerated id
 * 
 * @api
 */
Base.prototype.getId = function () {
  return this._id;
};

/**
 * The instance id will be autogenerated by incrementing this variable.
 */
Base.$nextId = 0;

/**
 * Static funciton used internally to autogenerate source ids.
 */
Base.$generateId = function () {
  return 'L' + ++Base.$nextId;
};

/**
 * Return the real carto.js model used by the layer.
 */
Base.prototype.$getInternalModel = function () {
  return this._internalModel;
};

module.exports = Base;
