module.exports={
  "name": "cartodb.js",
  "version": "4.0.0-alpha.25",
  "description": "CARTO javascript library",
  "repository": {
    "type": "git",
    "url": "git://github.com/CartoDB/cartodb.js.git"
  },
  "author": {
    "name": "CARTO",
    "url": "https://carto.com/",
    "email": "wadus@carto.com"
  },
  "contributors": [
    "Javier Álvarez <jmedina@carto.com>",
    "Javier Álvarez <xabel@vizzuality.com>",
    "Javier Arce <javierarce@carto.com>",
    "Javier Santana <jsantana@carto.com>",
    "Raul Ochoa <rochoa@carto.com>",
    "Carlos Matallín <matallo@carto.com>",
    "Jaime Chapinal <jaime.chapinal@carto.com>",
    "Nicklas Gummesson <nicklas@carto.com>",
    "Francisco Dans <francisco@carto.com>",
    "Emilio García <emilio@carto.com>"
  ],
  "license": "BSD-3-Clause",
  "dependencies": {
    "backbone": "1.2.3",
    "backbone-poller": "^1.1.3",
    "browserify-shim": "3.8.12",
    "camshaft-reference": "<2.0",
    "carto": "cartodb/carto#master",
    "clip-path-polygon": "0.1.12",
    "d3-array": "1.2.1",
    "d3-format": "1.2.0",
    "d3-time-format": "2.1.0",
    "jquery": "2.1.4",
    "moment": "^2.18.1",
    "moment-timezone": "^0.5.13",
    "mustache": "1.1.0",
    "perfect-scrollbar": "git://github.com/CartoDB/perfect-scrollbar.git#master",
    "postcss": "5.0.19",
    "tangram.cartodb": "~0.5.7",
    "torque.js": "CartoDB/torque#master",
    "underscore": "1.8.3"
  },
  "devDependencies": {
    "babel-preset-es2015": "~6.24.1",
    "babelify": "~7.3.0",
    "browserify": "13.0.0",
    "cartoassets": "CartoDB/CartoAssets#master",
    "eslint": "~4.8.0",
    "eslint-config-semistandard": "~11.0.0",
    "eslint-config-standard": "~10.2.1",
    "eslint-plugin-import": "~2.7.0",
    "eslint-plugin-node": "~5.2.0",
    "eslint-plugin-promise": "~3.5.0",
    "eslint-plugin-standard": "~3.0.1",
    "findup-sync": "0.1.3",
    "grunt": "0.4.5",
    "grunt-aws": "^0.4.0",
    "grunt-browserify": "5.0.0",
    "grunt-contrib-clean": "~0.5.0",
    "grunt-contrib-concat": "~0.3.0",
    "grunt-contrib-connect": "~0.11.2",
    "grunt-contrib-copy": "~0.7.0",
    "grunt-contrib-cssmin": "~0.7.0",
    "grunt-contrib-imagemin": "~1.0.0",
    "grunt-contrib-jasmine": "1.1.0",
    "grunt-contrib-uglify": "0.10.0",
    "grunt-contrib-watch": "git://github.com/gruntjs/grunt-contrib-watch.git#b884948805940c663b1cbb91a3c28ba8afdebf78",
    "grunt-eslint": "~20.1.0",
    "grunt-exorcise": "2.1.0",
    "grunt-fastly": "~0.1.3",
    "grunt-gitinfo": "~0.1.7",
    "grunt-prompt": "~1.3.0",
    "grunt-replace": "0.6.2",
    "grunt-sass": "2.0.0",
    "gulp": "3.8.10",
    "gulp-iconfont": "1.0.0",
    "gulp-iconfont-css": "0.0.9",
    "gulp-install": "0.2.0",
    "gulp-sketch": "0.0.7",
    "jasmine-ajax": "git://github.com/nobuti/jasmine-ajax.git#master",
    "jsdoc": "~3.5.5",
    "jstify": "0.12.0",
    "leaflet": "1.0.3",
    "load-grunt-tasks": "~0.6.0",
    "npm-watch": "^0.3.0",
    "semver": "~4.1.0",
    "source-map-support": "CartoDB/node-source-map-support#0.4.6-cdb1",
    "time-grunt": "~0.3.1",
    "watchify": "3.4.0"
  },
  "browserify": {
    "transform": [
      "browserify-shim",
      "jstify"
    ]
  },
  "browser": {
    "cdb": "./src/cdb.js",
    "cdb.config": "./src/cdb.config.js",
    "cdb.core.util": "./src/core/util.js",
    "cdb.core.Profiler": "./src/core/profiler.js",
    "cdb.log": "./src/cdb.log.js",
    "cdb.errors": "./src/cdb.errors.js",
    "cdb.templates": "./src/cdb.templates.js",
    "geojson": "./vendor/GeoJSON.js",
    "html-css-sanitizer": "./vendor/html-css-sanitizer-bundle.js",
    "lzma": "./vendor/lzma.js",
    "mousewheel": "./vendor/mousewheel.js",
    "mwheelIntent": "./vendor/mwheelIntent.js",
    "spin.js": "./vendor/spin.js",
    "wax.cartodb.js": "./vendor/wax.cartodb.js"
  },
  "browserify-shim": {
    "leaflet": "global:L",
    "geojson": "GeoJSON",
    "html-css-sanitizer": "html",
    "lzma": "LZMA",
    "mousewheel": {
      "depends": [
        "jquery:jQuery"
      ]
    },
    "mwheelIntent": {
      "depends": [
        "jquery:jQuery"
      ]
    },
    "spin.js": "Spinner",
    "wax.cartodb.js": "wax"
  },
  "files": [
    "dist",
    "node_modules/cdb",
    "src",
    "themes",
    "vendor"
  ],
  "main": "src/index.js",
  "config": {
    "root": "."
  },
  "scripts": {
    "test": "grunt test",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix",
    "docs": "rm -rf docs/public; jsdoc --configure config/jsdoc/public-conf.json",
    "docs:internal": "rm -rf docs/internal; jsdoc --configure config/jsdoc/internal-conf.json",
    "postversion": "git push origin v4 --follow-tags",
    "dist": "grunt build",
    "watch": "npm-watch"
  },
  "watch": {
    "docs": "src/**/*.js"
  }
}
