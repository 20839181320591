var Layer = require('./layer');
var EventTypes = require('./event-types');

/**
 *  @namespace carto.layer
 *  @api
 */
module.exports = {
  Layer: Layer,
  events: EventTypes
};
